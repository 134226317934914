<template>
  <div class="container">
    <ul>
      <li>
        <p>获取code</p>
        <button class="button"
                @click="getCode">获取code</button>
        <p>code:{{code}}</p>
      </li>
      <li>
        <p>获取access_token</p>
        <button class="button"
                @click="getAccessToken">获取access_token和openid</button>
        <p>access_token:{{access_token}}</p>
        <p>openid:{{openid}}</p>
      </li>
      <li>
        <p>发起支付</p>
        <button class="button"
                @click="pay">微信支付</button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data () {
    return {
      code: '',
      access_token: '',
      openid: ""
    }
  },
  methods: {
    getParam (name) {
      return (
        /* eslint-disable */
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || [, ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
    getCode () {
      this.code = this.getParam('code')
    },
    getAccessToken () {
      this.$http({
        url: '/getAccessToken?code=' + this.code
      }).then(res => {
        this.access_token = res.access_token
        this.openid = res.openid
        console.log(res)
      })
    },
    pay () {
      this.$http({
        url: '/getPrepayId'
      }).then(result => {
        console.log(result)
        WeixinJSBridge.invoke(
          'getBrandWCPayRequest', result,
          function (res) {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              // 使用以上方式判断前端返回,微信团队郑重提示：
              //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              alert('支付成功')
            }
          });
      })
    }
  }
}
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
.container {
  margin: 0 auto;
  width: 95%;
}
p {
  line-height: 40px;
  text-align: left;
}
.button {
  width: 100%;
  height: 45px;
  background: #33ce73;
  color: #fff;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
  border: none;
}
</style>
